import React, { useCallback, useEffect, useState } from 'react';
import { local } from '@xxxlgroup/hydra-utils/storage';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { COUNTRY_OVERLAY_QUERY } from 'components/CountryOverlay/CountryOverlay.query';
import CountryOverlayModal from 'components/CountryOverlay/components/CountryOverlayModal';
import withVendorListener from 'components/CountryOverlay/components/withVendorListener';
import Message from 'components/Message';
import { Types } from 'types';
import { useTracking } from 'utils/tracking/hooks';
import { tagComponent } from 'utils/tracking/tracking';
import track from 'react-tracking';
import useIsModeHeadless from 'hooks/useIsModeHeadless';

const isCountryOverlayOpen = local.getItem('countryOverlayClosed') !== true;

export const CountryOverlay = (props) => {
  const { countryOverlay } = props;

  const tracking = useTracking(props, 'CountryOverlay');
  const isModeHeadless = useIsModeHeadless();

  const [isModalOpen, setIsModalOpen] = useState(true);
  const [country, setCountry] = useState(null);
  const [originCountry, setOriginCountry] = useState(null);

  const shouldModalOpen =
    countryOverlay &&
    !countryOverlay.error &&
    !countryOverlay.loading &&
    isCountryOverlayOpen &&
    isModalOpen;

  const i18nModal = {
    close: <Message code="wxs.modal.closeButton.ariaLabel" />,
  };

  const getContentSlotsComponents = (arrCountryOverlay) =>
    arrCountryOverlay.contentSlots.map((item) => item && item.components)[0];

  const getOriginCountry = useCallback(
    (countryOverlaySlots) =>
      getContentSlotsComponents(countryOverlaySlots) &&
      [...getContentSlotsComponents(countryOverlaySlots)].pop() &&
      setOriginCountry(
        [...getContentSlotsComponents(countryOverlaySlots)]
          .pop()
          .iconName.replace('flag', '')
          .toLowerCase(),
      ),
    [],
  );

  const handleOnLoad = useCallback(() => {
    countryOverlay && countryOverlay.getCountryOverlay.contentSlots.length
      ? getOriginCountry(countryOverlay.getCountryOverlay)
      : setOriginCountry('noShop');
  }, [countryOverlay, getOriginCountry]);

  const handleClick = useCallback(
    (event) => {
      tracking(event, { props: { country: country || originCountry } });
      setIsModalOpen(false);
      local.setItem('countryOverlayClosed', true);
    },
    [country, originCountry, tracking],
  );

  const handleHover = useCallback(
    (event) => {
      tracking(event);
    },
    [tracking],
  );

  useEffect(() => {
    !isModeHeadless && shouldModalOpen && handleOnLoad();
  }, [handleOnLoad, isModeHeadless, shouldModalOpen]);

  useEffect(() => {
    originCountry &&
      tracking('show', {
        event: { type: 'show', purpose: 'modal.showOverlay' },
        props: { country: originCountry },
      });
  }, [originCountry, tracking]);

  if (!isModeHeadless && shouldModalOpen) {
    const { contentSlots } = countryOverlay.getCountryOverlay || {};
    return (
      <CountryOverlayModal
        contentSlots={contentSlots}
        onHover={handleHover}
        onClick={handleClick}
        i18n={i18nModal}
        onClose={handleClick}
        setCountry={setCountry}
      />
    );
  }

  return null;
};

CountryOverlay.propTypes = {
  countryOverlay: Types.graphqlResult({
    getCountryOverlay: PropTypes.shape({}),
  }),
};

CountryOverlay.defaultProps = {
  countryOverlay: null,
};

const getCookieIp = () => {
  const geoTestIp = local.getItem('geoTestIp');

  if (isCountryOverlayOpen || geoTestIp) {
    return geoTestIp;
  }

  return null;
};

const skipQuery = () => local.getItem('countryOverlayClosed') === true;

export default track(tagComponent('CountryOverlay'))(
  withVendorListener(
    graphql(COUNTRY_OVERLAY_QUERY, {
      name: 'countryOverlay',
      options: {
        variables: {
          ip: getCookieIp(),
        },
      },
      skip: skipQuery(),
    })(CountryOverlay),
  ),
);
