import React from 'react';
import useMessage from 'components/Message/useMessage';
import { Button } from '@xxxlgroup/hydra-ui-components';
import { arrowRight } from '@xxxlgroup/hydra-icons';
import PropTypes from 'prop-types';
import styles from 'components/CountryOverlay/components/CountrySelect/CountrySelect.scss';

const NoCountry = ({ onClose }) => {
  const [buttonText, infoText] = useMessage([
    'poseidon.countryoverlay.noshop.CTA',
    'poseidon.countryoverlay.noshop.text',
  ]);

  return (
    <>
      <p>{infoText}</p>
      <Button glyphAfter={arrowRight} className={styles.button} layout="block" onClick={onClose}>
        {buttonText}
      </Button>
    </>
  );
};

NoCountry.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default NoCountry;
