import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as icons from '@xxxlgroup/hydra-icons';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import { Button, FeedbackCard, RadioButton, RadioGroup } from '@xxxlgroup/hydra-ui-components';

import useMessage from 'components/Message/useMessage';
import Link from 'components/WebshopLink';

import styles from 'components/CountryOverlay/components/CountrySelect/CountrySelect.scss';

const CountrySelect = ({ items, onHover, onClick, setCountry }) => {
  const [selectedCountryIndex, selectCountry] = useState(0);
  const selectedCountryLink = items[selectedCountryIndex].poseidonLink?.url;
  const targetCountry = items[1];
  const isAtHome = selectedCountryIndex !== 0;
  const selectedCountryName = items[selectedCountryIndex].iconName
    .replace('flag', '')
    .toLowerCase();

  const [CTAText] = useMessage(['poseidon.countryoverlay.CTA']);

  const [switchText, infoText] = useMessage(
    ['poseidon.countryoverlay.text', 'poseidon.countryoverlay.info'],
    { 0: targetCountry?.title, 1: targetCountry?.link },
  );

  const onChange = useCallback(
    (value) => {
      selectCountry(value);
    },
    [selectCountry],
  );

  useEffect(() => {
    setCountry(selectedCountryName);
  }, [setCountry, selectedCountryName]);

  return (
    <>
      <p>{switchText}</p>

      <FeedbackCard variant="normal">{infoText}</FeedbackCard>

      <RadioGroup onChange={onChange} name="countryOverlayRadioButton" defaultValue={0} required>
        {items.map((item, index) => {
          const [iconStyleAfter, iconClassNameAfter] = pseudoIcon(icons[item.iconName], 'after');
          return (
            <RadioButton key={item.title} name={item.title} value={index}>
              <div className={classnames(styles.radio, iconClassNameAfter)} style={iconStyleAfter}>
                {item.title}
              </div>
            </RadioButton>
          );
        })}
      </RadioGroup>
      {isAtHome ? (
        <Link
          href={selectedCountryLink}
          target="_top"
          onClick={onClick}
          onMouseEnter={onHover}
          data-purpose="countryoverlay.link"
          as={<Button glyphAfter={icons.arrowRight} layout="block" />}
        >
          {CTAText}
        </Link>
      ) : (
        <Button
          glyphAfter={icons.arrowRight}
          layout="block"
          onClick={onClick}
          onMouseEnter={onHover}
          data-purpose="countryoverlay.link"
        >
          {CTAText}
        </Button>
      )}
    </>
  );
};

CountrySelect.propTypes = {
  onHover: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  setCountry: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
      poseidonLink: PropTypes.shape({
        url: PropTypes.string,
      }),
      iconName: PropTypes.string,
    }),
  ).isRequired,
};

export default CountrySelect;
