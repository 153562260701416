import React, { useState, useEffect } from 'react';

const withVendorListener = (Component) => (props) => {
  const [isCountryOverlayVisible, setVisibility] = useState(false);
  const handleVendorChange = (event) => {
    if (window?.jentis?.consent.engine.getConsentId) {
      const userconsent = window.jentis.consent.engine.getConsentId();
      userconsent && setVisibility(!!event.detail.cstm_country_overlay);
    }
  };
  useEffect(() => {
    document.addEventListener('jentis.consent.engine.vendor-change', handleVendorChange);

    return () =>
      document.removeEventListener('jentis.consent.engine.vendor-change', handleVendorChange);
  }, []);
  return isCountryOverlayVisible ? <Component {...props} /> : null;
};

export default withVendorListener;
