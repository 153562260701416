import { gql } from '@apollo/client';

export const COUNTRY_OVERLAY_QUERY = gql`
  query countryOverlay($ip: String) {
    getCountryOverlay(ip: $ip) {
      code
      contentSlots
    }
  }
`;
