import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@xxxlgroup/hydra-ui-components';
import NoCountry from 'components/CountryOverlay/components/NoCountry';
import CountrySelect from 'components/CountryOverlay/components/CountrySelect/CountrySelect';
import useMessage from 'components/Message/useMessage';
import styles from 'components/CountryOverlay/components/CountryOverlayModal/CountryOverlayModal.scss';

const CountryOverlayModal = ({ contentSlots, onHover, onClick, i18n, onClose, setCountry }) => {
  const items = [];
  const [header, noShopHeader] = useMessage([
    'poseidon.countryoverlay.header',
    'poseidon.countryoverlay.noshop.header',
  ]);

  contentSlots.forEach((contentSlot) => {
    contentSlot.components.forEach((component) => {
      if (component.restType === 'CmsImageComponent') {
        items.push(component);
      }
    });
  });

  return (
    <Modal
      heading={!items.length ? noShopHeader : header}
      className={styles.modal}
      i18n={i18n}
      onClose={onClose}
    >
      <div className={styles.content}>
        {!items.length ? (
          <NoCountry onClose={onClose} setCountry={setCountry} />
        ) : (
          <CountrySelect
            onHover={onHover}
            onClick={onClick}
            setCountry={setCountry}
            items={items}
          />
        )}
      </div>
    </Modal>
  );
};

CountryOverlayModal.propTypes = {
  contentSlots: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onHover: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  i18n: PropTypes.shape({}).isRequired,
  setCountry: PropTypes.func.isRequired,
};

export default CountryOverlayModal;
